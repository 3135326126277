<template>
    <div class="page-mall-detail">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path:'/integralMall'}">积分商城</el-breadcrumb-item>
                <el-breadcrumb-item>{{ goodsInfo.name }}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="mall-detail">
                <div class="pic" style="flex: inherit;max-width: 50%;">
                    <el-image
                        style="width: 500px;height: 350px"
                        :src="goodsInfo.pictureUrl || defaultImg"
                        fit="fill"></el-image>
<!--                    ['fill', 'contain', 'cover', 'none', 'scale-down'],-->
<!--                    <img style="" :src="goodsInfo.pictureUrl" alt="">-->
                </div>
                <div class="base-info">
                    <h3>{{ goodsInfo.name }}</h3>
                    <div class="info">
                        <p>
                            <span>商品编号</span>{{ goodsInfo.number }}
                        </p>
                        <p>
                            <span>库存</span>{{goodsInfo.stockTotal}}
                        </p>
                    </div>
                    <div class="integral">
                        <span>所需积分</span>
                        <p>
                            <span>{{ goodsInfo.point }}</span>
                            <em>积分</em>
                            <br>
                            <br>
                            (当前积分:{{ customJFtotal }}积分)
                        </p>
                    </div>
                    <div class="num">
                        <span class="label">数量</span>
                        <el-input-number v-model="goodnum" :min="1" :max="goodsInfo.stockTotal || 0"></el-input-number>
                    </div>
                    <div class="g-btns">
                        <div @click="goOrder" class="g-btn">立即兑换</div>
                        <div @click="joinJFcart" class="g-btn white">加入积分筐</div>
                    </div>
                </div>

            </div>
            <div class="mall-detail-content">
                <div class="title"><i></i>商品详情</div>
                <div v-html="goodsInfo.describeText"></div>
<!--                <img src="@/assets/img/mall-det.png" style="display: block;margin: 0 auto;max-width: 856px;width: 100%;" alt="">-->
            </div>
        </div>
    </div>
</template>

<script>
import {integralGoodsDetail,customerIntegralCartsave} from "@/api/Product";
import Bus from "@/utils/bus";

export default {
    name: "detail",
    data() {
        return {
            goodnum: 1, // 商品数量
            goodsInfo:{}, // 商品信息
            customJFtotal:0, // 积分总数

        }
    },
    mounted() {
        console.log(this.$route.query) // 获取传递参数
        this.getintegralGoodsDetail(this.$route.query.id)
        this.customJFtotal=JSON.parse(localStorage.getItem('customInfo')).integralTotal || 0
    },
    methods:{
        // 立即兑换
        goOrder(){
            // 需判断是否有token并且未过期
            if(localStorage.getItem('Token') && !this.$utils.isTokenExpired()){
                if(this.customJFtotal > this.goodsInfo.point ){
                    this.$router.push({path:'/IntegralMall/Exchange',query: {goodsIdlist:this.goodsInfo.id,goodsNums:this.goodnum}}) //
                }else{
                    this.$message({
                        type: 'warning',
                        message: '积分不足！',
                        duration: 2000
                    });
                }
            }else{
                Bus.$emit('re-login', '未登录')
            }

        },

        getintegralGoodsDetail(id){ // 获取积分商品详情
            integralGoodsDetail({id:id}).then((res) => {
                if (res.data.code==200) {
                    this.goodsInfo=res.data.data
                    this.goodsInfo.describeText=this.$utils.showHtml(res.data.data.describeText)

                }
            })
        },
        // 加入积分框
        joinJFcart(){
            let param={
                integralGoodsId:this.goodsInfo.id,
                customerId:localStorage.getItem('customId'),
                count:this.goodnum
            }
            customerIntegralCartsave(param).then((res) => {
                if (res.data.code==200) {
                    this.$message({
                        type: 'success',
                        message: "已加入积分框",
                        duration: 2000
                    });
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 2000
                    });
                }
            })
        },

    },
}
</script>

<style scoped>

</style>
